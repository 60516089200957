import React, {useEffect} from "react";
import RestaurantIntro from "../components/RestaurantIntro";
import RestaurantCatalog from "../components/RestaurantCatalog";
import RestaurantCatalogList from "../components/RestaurantCatalogList";
import {useDispatch} from "react-redux";
import {initCatalog, setAllCategories, setCurrentCategory} from "../services/catalogSlide";

export default function RestaurantGontainer({}) {


    const [catalogs, setCatalogs] = React.useState(undefined)
    const [catalog, setCatalog] = React.useState(undefined)
    const [displayIntro, setDisplayIntro] = React.useState(true)
    const [bannersLocal, setBannersLocal] = React.useState(undefined)
    const [displayCatalogList, setDisplayCatalogList] = React.useState(true)

    const dispatch = useDispatch()

    const sortAndPrepareCatalog = (catalog) => {
       // setDisplayIntro(false)
        const sortedC = catalog.catalog
        const categories = []
        sortedC.forEach((dishItem) => {
            if (!categories.includes(dishItem.category)) {
                categories.push(dishItem.category)
            }
        })
        console.log(sortedC)

        dispatch(initCatalog({
            startFromIndex: catalog.startFromIndex || 0,
            currentCatalogName: catalog.name,
            allCategories: categories,
            currentCategory: catalog.startFromFullIndex !== undefined ? catalog.catalog[catalog.startFromFullIndex].category : sortedC[0].category
        }))


        setCatalog({
            ...catalog,
            catalog: sortedC
        })
    }


    useEffect(() => {

        if (catalogs !== undefined && catalogs.length === 1) {

            sortAndPrepareCatalog(catalogs[0])

        } else if (catalogs !== undefined && catalogs.length > 1) {
            document.getElementById("RestaurantCatalogListContainer")?.scrollIntoView({behavior: 'smooth'})
            setTimeout(() => {
                document.getElementById("RestaurantIntro").style.display = "none"
                window.scrollTo({top: 1})
            }, 800)
        }

    }, [catalogs]);


    return (
        <div id="mainContainer" className={"mainContainer"}>
            { displayIntro ?
            <div id={"RestaurantIntro"} key={"RestaurantIntro"} item xs={1}>
                <RestaurantIntro setCatalogs={(catalogs) => {
                    setCatalogs(catalogs)
                }} setBannersLocal={setBannersLocal} sortAndPrepareCatalog={sortAndPrepareCatalog} setDisplayCatalogList={setDisplayCatalogList}/>
            </div> : null }
            <div>
                {
                    displayCatalogList && catalogs !== undefined && catalogs.length > 1 ?
                        <RestaurantCatalogList catalogs={catalogs} setCatalog={(cat) => {
                            sortAndPrepareCatalog(cat)
                        }} bannersLocal={bannersLocal}/> : null
                }
            </div>
            <div>
                {
                    catalog !== undefined ? <RestaurantCatalog sortedCatalog={catalog} goToIntro={()=> {
                        document.getElementById("RestaurantCatalogListContainer").style.display = "block";
                        document.getElementById("MenuHeader").style.display = "none";
                        document.getElementById("CategoryMenu").style.display = "none";
                        window.scrollTo({top: 1, behavior: 'smooth'});
                        setTimeout(() => {
                            setCatalog(undefined)
                        }, 500)
                    }}/> : null
                }
            </div>


        </div>
    )
}
