import {useDispatch, useSelector} from "react-redux";
import {setCurrentCategory} from "../services/catalogSlide";
import React, {useEffect} from "react";
import icon1 from "../images/icons/c0e634670a14a4ae4319a556f694df80.png";
import icon2 from "../images/icons/394fd9d9d1ddac983fafab27ef5aa1dd.png";
import icon3 from "../images/icons/c82fcc68c47c08f92f65f7ee0cb520db2.png";
import icon4 from "../images/icons/fa45ddb82c7790d4c686422ccd958e75.png";
import icon5 from "../images/icons/abbffb0a5f6705519f23d8a639b85e80.png";
import icon6 from "../images/icons/d548dba323da20792438371259501ad4.png";
import gunkan from "../images/icons/gunkan.png";
import gioza from "../images/icons/gyoza.png";
import niguiri from "../images/icons/niguiri.png";
import coctel from "../images/icons/Cocteles.png";
import uramaki from "../images/icons/uramaki.png";
export default function MenuCategory({}) {


    const mapCategoryIcons = {
        "Menu Noche": icon1,
        "Postre": icon2,
        "Arroces": icon5,
        "Carnes": icon3,
        "Nigiris": niguiri,
        "Bebidas": icon6,
        "Cocteles": coctel,
        "Gunkan" : gunkan,
        "Gyoza" : gioza,
        "Maki": uramaki

    }


    const allCategories = useSelector((state) => state.catalog.allCategories)
    const heeaderToDisplay = useSelector(state => state.interaction.headerToDisplay)
    const currentCategory = useSelector(state => state.catalog.currentCategory)
    const [currentToSet, setCurrentToSet] = React.useState(currentCategory)
    const dispatch = useDispatch()

    useEffect(() => {
        setCurrentToSet(heeaderToDisplay)
    }, [heeaderToDisplay])

    //Return a horizontal scrollable list using the Grid2 component
    return (
        <div className={"CategoryMenu"} id={"CategoryMenu"} style={{display: "none"}}>
            {allCategories.map((item, index, idx) => {
                return <div className={"categoryMenuItem"} style={{background: currentToSet === item ? "black" : "black"}} onClick={() => {
                    dispatch(setCurrentCategory(item))
                    setTimeout(() => {
                        setCurrentToSet(item)
                    }, 500)
                }}>
                    <img height={38} src={mapCategoryIcons[item]} alt={item} />
                </div>
            })}
        </div>
    )

}
