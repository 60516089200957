import {Container} from "@mui/material";
import React, {useEffect} from "react";
import {setDishes, setRestaurantCode} from "../services/catalogSlide";
import {useDispatch} from "react-redux";
import Grid2 from "@mui/material/Unstable_Grid2";
import eyebiteGraphicLogo from "../images/Eyebite_graphic_logo.png";
import eyebiteWrittenLogo from "../images/Eyebite_written_logo.png";

export default function RestaurantIntro({setCatalogs, sortAndPrepareCatalog, setBannersLocal,setDisplayCatalogList}) {

    //Get PathParams /_restaurantId/:dishname
    const pathParams = window.location.pathname.split("/")
    const restaurantId = pathParams[1]
    console.log(pathParams)
    let restaurantsCatalog = [
        {
            "name": "Rest1",
            "code": "Rest1",
            "location": {
                "address": "123 Flavor Street, Gourmet City, Country",
                "latitude": 40.712776,
                "longitude": -74.005974
            },
            "logoRest": "",
            "bannerRest": "",
            bannerLocalHeader: "",
            bannerLocalFooter: "",
            catalogs: [{
                "name": "Restaurante 1",
                "mainImage": "",
                "categoryOrder": [
                    "Cocteles",
                    "Maki",
                    "Nigiris",
                    "Gunkan",
                    "Gyoza",
                    "Postre"
                ],
                "catalog": [
                    {
                        "dishName": "Caipirinha de frutos rojos",
                        "price": 12.0,
                        "allergens": [
                            "nuts"
                        ],
                        "descriptionEn": "A vibrant twist on the Brazilian classic, blending cachaça with a selection of fresh red fruits, brown sugar and a hint of lime for a fresh finish.",
                        "description": "Un giro vibrante en el clásico brasileño, mezclando cachaça con una selección de frutos rojos frescos, azúcar moreno y un toque de lima para un acabado fresco.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6847.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Red Berry Caipirinha"
                    },
                    {
                        "dishName": "Absolut Cobalt Cloud",
                        "price": 11.0,
                        "allergens": [
                            "nuts"
                        ],
                        "descriptionEn": "A futuristic and sophisticated cocktail combining Absolut vodka with a touch of blueberry liqueur and coconut cream, served in a glass garnished with candyfloss clouds.",
                        "description": "Un cóctel futurista y sofisticado que combina vodka Absolut con un toque de licor de arándano y crema de coco, servido en una copa adornada con nubes de algodón de azúcar.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6816.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Absolut Cobalt Cloud"
                    },
                    {
                        "dishName": "Daiquiri de melocotón",
                        "price": 10.0,
                        "allergens": [],
                        "descriptionEn": "A refreshing twist on the classic daiquiri, with white rum, freshly squeezed peach juice and a squeeze of lime, garnished with a slice of peach.",
                        "description": "Un refrescante twist en el clásico daiquiri, con ron blanco, jugo de melocotón recién exprimido y un chorrito de limón, adornado con una rodaja de melocotón.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6854.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Peach Daiquiri"
                    },
                    {
                        "dishName": "Margarita de naranja sanguina",
                        "price": 10.0,
                        "allergens": [],
                        "descriptionEn": "This exotic version of the margarita mixes tequila with the juice of blood oranges, triple sec and a touch of lime, garnished with salt on the rim of the glass.",
                        "description": "Esta versión exótica del margarita mezcla tequila con el jugo de naranjas sanguinas, triple sec y un toque de lima, decorada con sal en el borde de la copa.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6836.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Blood Orange Margarita"
                    },
                    {
                        "dishName": "Mojito clásico",
                        "price": 12.0,
                        "allergens": [],
                        "descriptionEn": "The authentic Cuban mojito, with rum, fresh mint, sugar, lime and a splash of soda, perfect for refreshing any evening.",
                        "description": "El auténtico mojito cubano, con ron, menta fresca, azúcar, lima y un chorro de soda, perfecto para refrescar cualquier noche.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6834.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Classic Mojito"
                    },
                    {
                        "dishName": "Lemon Drop",
                        "price": 14.0,
                        "allergens": [],
                        "descriptionEn": "A classic and elegant cocktail with vodka, lemon liqueur and a touch of sugar, served in a cocktail glass with a sugared rim for a sweet finish.",
                        "description": "Un cóctel clásico y elegante con vodka, licor de limón y un toque de azúcar, servido en una copa de cóctel con el borde azucarado para un final dulce.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6331.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Lemon Drop"
                    },
                    {
                        "dishName": "Pisco pistacho",
                        "price": 12.0,
                        "allergens": [
                            "nuts"
                        ],
                        "descriptionEn": "Exotic cocktail of pisco infused with pistachios, garnished with a touch of lime and almond syrup.",
                        "description": "Cóctel exótico de pisco infusionado con pistachos, adornado con un toque de lima y sirope de almendra.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_0745.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Pistachio Pisco"
                    },
                    {
                        "dishName": "Margarita de maracuja",
                        "price": 12.0,
                        "allergens": [],
                        "descriptionEn": "Tropical Margarita with tequila and fresh maracuja juice, served with a salt rim and a slice of lime.",
                        "description": "Margarita tropical con tequila y jugo fresco de maracujá, servida con un borde de sal y una rodaja de lima.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6852.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Passionfruit Margarita"
                    },
                    {
                        "dishName": "Nigiri de anguila",
                        "price": 7.0,
                        "allergens": [
                            "fish",
                            "nuts"
                        ],
                        "descriptionEn": "Nigiri de anguila kabayaki y hoja de sisho en tempura",
                        "description": "Nigiri de anguila kabayaki y hoja de sisho en tempura",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6820.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Eel Nigiri"
                    },
                    {
                        "dishName": "Nigiri cítrico",
                        "price": 7.0,
                        "allergens": [
                            "fish",
                            "sesame"
                        ],
                        "descriptionEn": "Flambéed salmon nigiri with citrus sauce",
                        "description": "Nigiri de salmón flambeado con salsa cítrica",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6342.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Citrus Nigiri"
                    },
                    {
                        "dishName": "Nigiri clásico",
                        "price": 7.0,
                        "allergens": [
                            "fish"
                        ],
                        "descriptionEn": "Salmon nigiri with moriawase roe",
                        "description": "Nigiri de salmón con huevas moriawase",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6278.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Classic Nigiri"
                    },
                    {
                        "dishName": "Nigiri a la brasa",
                        "price": 7.0,
                        "allergens": [
                            "fish"
                        ],
                        "descriptionEn": "Braised langoustine nigiri",
                        "description": "Nigiri de langostino braseado",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6702.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [
                            "starDish"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Grilled Nigiri"
                    },
                    {
                        "dishName": "Nigiri con Foie",
                        "price": 10.0,
                        "allergens": [
                            "nuts"
                        ],
                        "descriptionEn": "Nigiri de wagyu con foie flambeado",
                        "description": "Nigiri de wagyu con foie flambeado",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6340 2.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [
                            "ChefRecommended"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Nigiri with Foie"
                    },
                    {
                        "dishName": "Nigiri de gamba",
                        "price": 8.0,
                        "allergens": [
                            "fish",
                            "soy"
                        ],
                        "descriptionEn": "Prawn confit nigiri with mayonnaise",
                        "description": "Nigiri de gamba confitada con mayonesa",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6819.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Shrimp Nigiri"
                    },
                    {
                        "dishName": "Nigiri de pez mantequilla",
                        "price": 8.0,
                        "allergens": [
                            "fish",
                            "soy"
                        ],
                        "descriptionEn": "Butterfish nigiri with ponzu",
                        "description": "Nigiri de pez mantequilla con ponzu",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6704.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Butterfish Nigiri"
                    },
                    {
                        "dishName": "Nigiri de zamburiña",
                        "price": 8.0,
                        "allergens": [
                            "fish"
                        ],
                        "descriptionEn": "Prawn nigiri with sweet soybeans",
                        "description": "Nigiri de zamburiña con soja dulce",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_0763.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Scallop Nigiri"
                    },
                    {
                        "dishName": "Nigiri de vieira",
                        "price": 10.0,
                        "allergens": [
                            "fish"
                        ],
                        "descriptionEn": "Flamed scallop nigiri with lemon and salt",
                        "description": "Nigiri de vieira flameada con limón y sal",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_0751.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Scallop Nigiri"
                    },
                    {
                        "dishName": "Nigiri de caballa",
                        "price": 7.0,
                        "allergens": [
                            "fish"
                        ],
                        "descriptionEn": "Marinated mackerel nigiri with pickled ginger",
                        "description": "Nigiri de caballa marinada con jengibre encurtido",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_0754.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Mackerel Nigiri"
                    },
                    {
                        "dishName": "Torrija",
                        "price": 7.5,
                        "allergens": [
                            "eggs",
                            "dairy"
                        ],
                        "descriptionEn": "Classic Spanish torrija dipped in milk, sprinkled with cinnamon and served with honey.",
                        "description": "Clásica torrija española bañada en leche, espolvoreada con canela y servida con miel.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_6803.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "French Toast"
                    },
                    {
                        "dishName": "Brownie de chocolate",
                        "price": 6.0,
                        "allergens": [
                            "eggs",
                            "dairy"
                        ],
                        "descriptionEn": "Dense chocolate brownie with walnuts, served with chocolate sauce.",
                        "description": "Brownie denso de chocolate con nueces, servido con salsa de chocolate.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_6805.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Chocolate Brownie"
                    },
                    {
                        "dishName": "Tarta de manzana",
                        "price": 6.0,
                        "allergens": [
                            "dairy"
                        ],
                        "descriptionEn": "Spiced apple crisp cake, served warm with vanilla ice cream.",
                        "description": "Tarta crujiente de manzana especiada, servida caliente con helado de vainilla.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_6798.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Apple Pie"
                    },
                    {
                        "dishName": "Brownie con helado",
                        "price": 6.0,
                        "allergens": [
                            "eggs",
                            "dairy"
                        ],
                        "descriptionEn": "Hot chocolate brownie with vanilla ice cream and chocolate sauce.",
                        "description": "Brownie de chocolate caliente con helado de vainilla y salsa de chocolate.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_6723.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Brownie with Ice Cream"
                    },
                    {
                        "dishName": "Tarta matilda",
                        "price": 7.0,
                        "allergens": [
                            "eggs",
                            "dairy"
                        ],
                        "descriptionEn": "Chocolate cake with ganache and crunchy chocolate pieces.",
                        "description": "Tarta de chocolate con ganache y trozos de chocolate crujiente.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_6779.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Matilda Cake"
                    },
                    {
                        "dishName": "Tiramisu",
                        "price": 6.0,
                        "allergens": [
                            "eggs",
                            "dairy",
                            "nuts"
                        ],
                        "descriptionEn": "Tiramisu with sponge cakes in coffee and amaretto, mascarpone cream and cocoa.",
                        "description": "Tiramisú con bizcochos en café y amaretto, crema de mascarpone y cacao.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_6780.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Tiramisu"
                    },
                    {
                        "dishName": "Tarta de queso",
                        "price": 7.5,
                        "allergens": [
                            "eggs",
                            "dairy"
                        ],
                        "descriptionEn": "Soft cheesecake sprinkled with poppy seeds, accompanied by a delicate hibiscus sauce.",
                        "description": "Suave tarta de queso espolvoreada con semillas de amapola, acompañada de una delicada salsa de hibiscus.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_0707.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Cheesecake"
                    },
                    {
                        "dishName": "Tartine de chocolate con sorbete de fresa",
                        "price": 6.0,
                        "allergens": [
                            "eggs"
                        ],
                        "descriptionEn": "Elegant dark chocolate tartine served with a refreshing homemade strawberry sorbet.",
                        "description": "Elegante tartine de chocolate oscuro servido con un refrescante sorbete de fresa casero.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_0716.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [
                            "starDish"
                        ],
                        "diets": [],
                        "dishNameEn": "Chocolate Tartine with Strawberry Sorbet"
                    },
                    {
                        "dishName": "Macaron de maracujá",
                        "price": 6.0,
                        "allergens": [
                            "nuts",
                            "eggs"
                        ],
                        "descriptionEn": "Crunchy macaron with a creamy maracuja filling, perfect for a tropical touch.",
                        "description": "Macaron crujiente con un relleno cremoso de maracujá, perfecto para un toque tropical.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_0714.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [
                            "ChefRecommended"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Passionfruit Macaron"
                    },
                    {
                        "dishName": "Petit four",
                        "price": 6.0,
                        "allergens": [
                            "eggs"
                        ],
                        "descriptionEn": "Small bites of airy meringue with a hint of lime and lemon citrus, a sweet acidity that delights.",
                        "description": "Pequeños bocados de aireado merengue con un toque cítrico de lima y limón, una dulce acidez que encanta.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_0708.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Petit Four"
                    },
                    {
                        "dishName": "Bowl de acai y yogur",
                        "price": 8.0,
                        "allergens": [
                            "dairy",
                            "nuts"
                        ],
                        "descriptionEn": "Nutritious acai bowl blended with natural yoghurt, topped with a selection of fresh fruit and crunchy granola.",
                        "description": "Bowl nutritivo de acai mezclado con yogur natural, coronado con una selección de frutas frescas y granola crujiente.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_0706.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Acai and Yogurt Bowl"
                    },
                    {
                        "dishName": "Pasteis de nata",
                        "price": 6.0,
                        "allergens": [
                            "eggs",
                            "dairy",
                            "nuts"
                        ],
                        "descriptionEn": "Traditional Portuguese custard tarts, with a crunchy base and a soft, sweet filling, sprinkled with cinnamon.",
                        "description": "Tradicionales tartas portuguesas de crema, con una base crujiente y un relleno suave y dulce, espolvoreadas con canela.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_0711.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Portuguese Custard Tart"
                    },
                    {
                        "dishName": "Gunkan de yema de huevo de codorniz",
                        "price": 7.0,
                        "allergens": [
                            "eggs",
                            "fish"
                        ],
                        "descriptionEn": "Rice gunkan wrapped in nori, topped with a soft-boiled quail egg yolk.",
                        "description": "Gunkan de arroz envuelto en nori, coronado con una yema de huevo de codorniz suavemente cocida.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gunkan/IMG_0845.webp",
                        "video": "",
                        "category": "Gunkan",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Quail Egg Gunkan"
                    },
                    {
                        "dishName": "Gunkan de molleja y crema de palta",
                        "price": 8.0,
                        "allergens": [
                            "nuts"
                        ],
                        "descriptionEn": "Delicate rice gunkan with tender sweetbread and a soft avocado cream, wrapped in nori.",
                        "description": "Delicado Gunkan de arroz con molleja tierna y una crema suave de palta, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gunkan/IMG_0852.webp",
                        "video": "",
                        "category": "Gunkan",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Gizzard and Avocado Cream Gunkan"
                    },
                    {
                        "dishName": "Gunkan de atún rojo picante",
                        "price": 8.0,
                        "allergens": [
                            "fish",
                            "soy"
                        ],
                        "descriptionEn": "Spicy gunkan with chopped fresh red tuna and a hint of chilli, wrapped in nori.",
                        "description": "Gunkan picante con atún rojo fresco picado y un toque de chile, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gunkan/IMG_0815.webp",
                        "video": "",
                        "category": "Gunkan",
                        "subcategory": "",
                        "features": [
                            "spicy"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Spicy Red Tuna Gunkan"
                    },
                    {
                        "dishName": "Gunkan de wagyu",
                        "price": 10.0,
                        "allergens": [
                            "soy"
                        ],
                        "descriptionEn": "Unique gunkan with lightly grilled wagyu beef over rice, wrapped in nori.",
                        "description": "Gunkan exclusivo con carne de wagyu ligeramente asada sobre arroz, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gunkan/IMG_0826.webp",
                        "video": "",
                        "category": "Gunkan",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Wagyu Gunkan"
                    },
                    {
                        "dishName": "Gunkan de anguila",
                        "price": 7.0,
                        "allergens": [
                            "fish"
                        ],
                        "descriptionEn": "Rice gunkan with grilled eel and a sweet unagi sauce, wrapped in a nori band.",
                        "description": "Gunkan de arroz con anguila grillada y una salsa unagi dulce, envuelto en una banda de nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gunkan/IMG_0759.webp",
                        "video": "",
                        "category": "Gunkan",
                        "subcategory": "",
                        "features": [
                            "starDish"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Eel Gunkan"
                    },
                    {
                        "dishName": "Gunkan de erizo de mar",
                        "price": 9.0,
                        "allergens": [
                            "fish"
                        ],
                        "descriptionEn": "Rice gunkan topped with fresh and creamy sea urchin, wrapped in nori.",
                        "description": "Gunkan de arroz cubierto con erizo de mar fresco y cremoso, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gunkan/IMG_0764.webp",
                        "video": "",
                        "category": "Gunkan",
                        "subcategory": "",
                        "features": [
                            "ChefRecommended"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Sea Urchin Gunkan"
                    },
                    {
                        "dishName": "Maki de salmón con espárragos",
                        "price": 6.5,
                        "allergens": [
                            "fish",
                            "soy"
                        ],
                        "descriptionEn": "Rolled maki with fresh salmon and tender asparagus, served with a light teriyaki sauce.",
                        "description": "Maki enrollado con salmón fresco y tiernos espárragos, servido con una ligera salsa teriyaki.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/maki/IMG_0847.webp",
                        "video": "",
                        "category": "Maki",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Salmon and Asparagus Maki"
                    },
                    {
                        "dishName": "Maki de tempura de camarón",
                        "price": 6.5,
                        "allergens": [
                            "fish",
                            "soy"
                        ],
                        "descriptionEn": "Crispy tempura shrimp maki with avocado and a touch of spicy mayonnaise, wrapped in nori.",
                        "description": "Maki crujiente de camarón en tempura con aguacate y un toque de mayonesa picante, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/maki/IMG_0832.webp",
                        "video": "",
                        "category": "Maki",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Shrimp Tempura Maki"
                    },
                    {
                        "dishName": "Maki de tobiko y pepino",
                        "price": 6.5,
                        "allergens": [
                            "fish",
                            "dairy"
                        ],
                        "descriptionEn": "Refreshing cucumber maki with flying fish roe (tobiko) and cream cheese, wrapped in nori.",
                        "description": "Refrescante maki de pepino con huevas de pez volador (tobiko) y queso crema, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/maki/IMG_0817.webp",
                        "video": "",
                        "category": "Maki",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Tobiko and Cucumber Maki"
                    },
                    {
                        "dishName": "Maki de pollo teriyaki",
                        "price": 5.5,
                        "allergens": [],
                        "descriptionEn": "Maki with grilled chicken in teriyaki sauce, served with green onion and wrapped in nori.",
                        "description": "Maki con pollo asado en salsa teriyaki, acompañado de cebolla verde y envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/maki/IMG_0854.webp",
                        "video": "",
                        "category": "Maki",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Teriyaki Chicken Maki"
                    },
                    {
                        "dishName": "Maki de atún picante",
                        "price": 6.5,
                        "allergens": [
                            "fish"
                        ],
                        "descriptionEn": "Maki with spicy tuna and avocado, seasoned with a mixture of sriracha and Japanese mayonnaise, wrapped in nori.",
                        "description": "Maki con atún picante y aguacate, sazonado con una mezcla de sriracha y mayonesa japonesa, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/maki/IMG_0811.webp",
                        "video": "",
                        "category": "Maki",
                        "subcategory": "",
                        "features": [
                            "spicy"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Spicy Tuna Maki"
                    },
                    {
                        "dishName": "Maki de unagi y mango",
                        "price": 6.5,
                        "allergens": [
                            "fish"
                        ],
                        "descriptionEn": "Exotic grilled eel (unagi) maki with fresh mango slices and a touch of unagi sauce, wrapped in nori.",
                        "description": "Exótico maki de anguila (unagi) a la parrilla con rodajas de mango fresco y un toque de salsa unagi, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/maki/IMG_0824.webp",
                        "video": "",
                        "category": "Maki",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "dishNameEn": "Unagi and Mango Maki"
                    },
                    {
                        "dishName": "Gyoza de cerdo",
                        "price": 6.5,
                        "allergens": [
                            "soy"
                        ],
                        "descriptionEn": "Gyozas stuffed with pork and vegetables, grilled.",
                        "description": "Gyozas rellenas de cerdo y vegetales, a la plancha.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gyoza/IMG_0830.webp",
                        "video": "",
                        "category": "Gyoza",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Pork Gyoza"
                    },
                    {
                        "dishName": "Gyoza de camarón",
                        "price": 6.5,
                        "allergens": [
                            "fish",
                            "soy"
                        ],
                        "descriptionEn": "Gyozas stuffed with chopped shrimp and spring onion.",
                        "description": "Gyozas rellenas de camarón picado y cebollín.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gyoza/IMG_0851.webp",
                        "video": "",
                        "category": "Gyoza",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Shrimp Gyoza"
                    },
                    {
                        "dishName": "Gyoza vegetariana",
                        "price": 6.5,
                        "allergens": [
                            "soy"
                        ],
                        "descriptionEn": "Tofu, shiitake and spinach gyozas.",
                        "description": "Gyozas de tofu, shiitake y espinacas.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gyoza/IMG_0806.webp",
                        "video": "",
                        "category": "Gyoza",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Vegetarian Gyoza"
                    },
                    {
                        "dishName": "Gyoza de pollo al curry",
                        "price": 6.5,
                        "allergens": [
                            "soy",
                            "nuts"
                        ],
                        "descriptionEn": "Chicken gyozas seasoned with mild curry.",
                        "description": "Gyozas de pollo sazonado con curry suave.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gyoza/IMG_0813.webp",
                        "video": "",
                        "category": "Gyoza",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Curry Chicken Gyoza"
                    },
                    {
                        "dishName": "Gyoza de ternera y cebolla",
                        "price": 6.5,
                        "allergens": [
                            "soy"
                        ],
                        "descriptionEn": "Gyozas stuffed with minced beef and caramelised onion.",
                        "description": "Gyozas rellenas de ternera picada y cebolla caramelizada.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gyoza/IMG_0822.webp",
                        "video": "",
                        "category": "Gyoza",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Beef and Onion Gyoza"
                    },
                    {
                        "dishName": "Gyoza de pato con ciruelas",
                        "price": 6.5,
                        "allergens": [
                            "soy"
                        ],
                        "descriptionEn": "Duck gyozas with sweet plum filling.",
                        "description": "Gyozas de pato con relleno de ciruelas dulces.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gyoza/IMG_0804.webp",
                        "video": "",
                        "category": "Gyoza",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "dishNameEn": "Duck and Plum Gyoza"
                    }
                ]
            }, {
                "name": "Restaurante 2",
                "mainImage": "",
                "categoryOrder": [
                    "Cocteles",
                    "Maki",
                    "Nigiris",
                    "Gunkan",
                    "Gyoza",
                    "Postre"
                ],
                "catalog": [
                    {
                        "dishName": "Caipirinha de frutos rojos",
                        "price": 12.0,
                        "allergens": [
                            "nuts"
                        ],
                        "description": "Un giro vibrante en el clásico brasileño, mezclando cachaça con una selección de frutos rojos frescos, azúcar moreno y un toque de lima para un acabado fresco.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6847.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "A vibrant twist on the Brazilian classic, blending cachaça with a selection of fresh red fruits, brown sugar and a hint of lime for a fresh finish.",
                        "dishNameEn": "Purple fruit caipirinha"
                    },
                    {
                        "dishName": "Absolut Cobalt Cloud",
                        "price": 11.0,
                        "allergens": [
                            "nuts"
                        ],
                        "description": "Un cóctel futurista y sofisticado que combina vodka Absolut con un toque de licor de arándano y crema de coco, servido en una copa adornada con nubes de algodón de azúcar.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6816.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "A futuristic and sophisticated cocktail combining Absolut vodka with a touch of blueberry liqueur and coconut cream, served in a glass garnished with candyfloss clouds.",
                        "dishNameEn": "Absolute Cobalt Cloud"
                    },
                    {
                        "dishName": "Daiquiri de melocotón",
                        "price": 10.0,
                        "allergens": [],
                        "description": "Un refrescante twist en el clásico daiquiri, con ron blanco, jugo de melocotón recién exprimido y un chorrito de limón, adornado con una rodaja de melocotón.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6854.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "A refreshing twist on the classic daiquiri, with white rum, freshly squeezed peach juice and a squeeze of lime, garnished with a slice of peach.",
                        "dishNameEn": "Peach Daiquiri"
                    },
                    {
                        "dishName": "Margarita de naranja sanguina",
                        "price": 10.0,
                        "allergens": [],
                        "description": "Esta versión exótica del margarita mezcla tequila con el jugo de naranjas sanguinas, triple sec y un toque de lima, decorada con sal en el borde de la copa.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6836.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "This exotic version of the margarita mixes tequila with the juice of blood oranges, triple sec and a touch of lime, garnished with salt on the rim of the glass.",
                        "dishNameEn": "Blood orange margarita"
                    },
                    {
                        "dishName": "Mojito clásico",
                        "price": 12.0,
                        "allergens": [],
                        "description": "El auténtico mojito cubano, con ron, menta fresca, azúcar, lima y un chorro de soda, perfecto para refrescar cualquier noche.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6834.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "The authentic Cuban mojito, with rum, fresh mint, sugar, lime and a splash of soda, perfect for refreshing any evening.",
                        "dishNameEn": "Classic Mojito"
                    },
                    {
                        "dishName": "Lemon Drop",
                        "price": 14.0,
                        "allergens": [],
                        "description": "Un cóctel clásico y elegante con vodka, licor de limón y un toque de azúcar, servido en una copa de cóctel con el borde azucarado para un final dulce.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6331.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "A classic and elegant cocktail with vodka, lemon liqueur and a touch of sugar, served in a cocktail glass with a sugared rim for a sweet finish.",
                        "dishNameEn": "Lemon Drop"
                    },
                    {
                        "dishName": "Pisco pistacho",
                        "price": 12.0,
                        "allergens": [
                            "nuts"
                        ],
                        "description": "Cóctel exótico de pisco infusionado con pistachos, adornado con un toque de lima y sirope de almendra.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_0745.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Exotic cocktail of pisco infused with pistachios, garnished with a touch of lime and almond syrup.",
                        "dishNameEn": "Pisco pistacho"
                    },
                    {
                        "dishName": "Margarita de maracuja",
                        "price": 12.0,
                        "allergens": [],
                        "description": "Margarita tropical con tequila y jugo fresco de maracujá, servida con un borde de sal y una rodaja de lima.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/cocteles/IMG_6852.webp",
                        "video": "",
                        "category": "Cocteles",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Tropical Margarita with tequila and fresh maracuja juice, served with a salt rim and a slice of lime.",
                        "dishNameEn": "Maracuja margarita"
                    },
                    {
                        "dishName": "Nigiri de anguila",
                        "price": 7.0,
                        "allergens": [
                            "fish",
                            "nuts"
                        ],
                        "description": "Nigiri de anguila kabayaki y hoja de sisho en tempura",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6820.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Nigiri de anguila kabayaki y hoja de sisho en tempura",
                        "dishNameEn": "Eel Nigiri"
                    },
                    {
                        "dishName": "Nigiri cítrico",
                        "price": 7.0,
                        "allergens": [
                            "fish",
                            "sesame"
                        ],
                        "description": "Nigiri de salmón flambeado con salsa cítrica",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6342.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Flambéed salmon nigiri with citrus sauce",
                        "dishNameEn": "Citrus nigiri"
                    },
                    {
                        "dishName": "Nigiri clásico",
                        "price": 7.0,
                        "allergens": [
                            "fish"
                        ],
                        "description": "Nigiri de salmón con huevas moriawase",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6278.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Salmon nigiri with moriawase roe",
                        "dishNameEn": "Classic Nigiri"
                    },
                    {
                        "dishName": "Nigiri a la brasa",
                        "price": 7.0,
                        "allergens": [
                            "fish"
                        ],
                        "description": "Nigiri de langostino braseado",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6702.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [
                            "starDish"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Braised langoustine nigiri",
                        "dishNameEn": "Nigiri a la brasa"
                    },
                    {
                        "dishName": "Nigiri con Foie",
                        "price": 10.0,
                        "allergens": [
                            "nuts"
                        ],
                        "description": "Nigiri de wagyu con foie flambeado",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6340 2.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [
                            "ChefRecommended"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Nigiri de wagyu con foie flambeado",
                        "dishNameEn": "Nigiri with Foie"
                    },
                    {
                        "dishName": "Nigiri de gamba",
                        "price": 8.0,
                        "allergens": [
                            "fish",
                            "soy"
                        ],
                        "description": "Nigiri de gamba confitada con mayonesa",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6819.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Prawn confit nigiri with mayonnaise",
                        "dishNameEn": "Nigiri de gamba"
                    },
                    {
                        "dishName": "Nigiri de pez mantequilla",
                        "price": 8.0,
                        "allergens": [
                            "fish",
                            "soy"
                        ],
                        "description": "Nigiri de pez mantequilla con ponzu",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_6704.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Butterfish nigiri with ponzu",
                        "dishNameEn": "Butterfish nigiri"
                    },
                    {
                        "dishName": "Nigiri de zamburiña",
                        "price": 8.0,
                        "allergens": [
                            "fish"
                        ],
                        "description": "Nigiri de zamburiña con soja dulce",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_0763.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Prawn nigiri with sweet soybeans",
                        "dishNameEn": "Nigiri de zamburiña"
                    },
                    {
                        "dishName": "Nigiri de vieira",
                        "price": 10.0,
                        "allergens": [
                            "fish"
                        ],
                        "description": "Nigiri de vieira flameada con limón y sal",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_0751.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Flamed scallop nigiri with lemon and salt",
                        "dishNameEn": "Scallop nigiri"
                    },
                    {
                        "dishName": "Nigiri de caballa",
                        "price": 7.0,
                        "allergens": [
                            "fish"
                        ],
                        "description": "Nigiri de caballa marinada con jengibre encurtido",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/niguiris/IMG_0754.webp",
                        "video": "",
                        "category": "Nigiris",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Marinated mackerel nigiri with pickled ginger",
                        "dishNameEn": "Mackerel nigiri"
                    },
                    {
                        "dishName": "Torrija",
                        "price": 7.5,
                        "allergens": [
                            "eggs",
                            "dairy"
                        ],
                        "description": "Clásica torrija española bañada en leche, espolvoreada con canela y servida con miel.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_6803.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Classic Spanish torrija dipped in milk, sprinkled with cinnamon and served with honey.",
                        "dishNameEn": "Torrija"
                    },
                    {
                        "dishName": "Brownie de chocolate",
                        "price": 6.0,
                        "allergens": [
                            "eggs",
                            "dairy"
                        ],
                        "description": "Brownie denso de chocolate con nueces, servido con salsa de chocolate.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_6805.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Dense chocolate brownie with walnuts, served with chocolate sauce.",
                        "dishNameEn": "Chocolate brownie"
                    },
                    {
                        "dishName": "Tarta de manzana",
                        "price": 6.0,
                        "allergens": [
                            "dairy"
                        ],
                        "description": "Tarta crujiente de manzana especiada, servida caliente con helado de vainilla.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_6798.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Spiced apple crisp cake, served warm with vanilla ice cream.",
                        "dishNameEn": "Apple pie"
                    },
                    {
                        "dishName": "Brownie con helado",
                        "price": 6.0,
                        "allergens": [
                            "eggs",
                            "dairy"
                        ],
                        "description": "Brownie de chocolate caliente con helado de vainilla y salsa de chocolate.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_6723.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Hot chocolate brownie with vanilla ice cream and chocolate sauce.",
                        "dishNameEn": "Brownie con helado"
                    },
                    {
                        "dishName": "Tarta matilda",
                        "price": 7.0,
                        "allergens": [
                            "eggs",
                            "dairy"
                        ],
                        "description": "Tarta de chocolate con ganache y trozos de chocolate crujiente.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_6779.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Chocolate cake with ganache and crunchy chocolate pieces.",
                        "dishNameEn": "Tarta matilda"
                    },
                    {
                        "dishName": "Tiramisu",
                        "price": 6.0,
                        "allergens": [
                            "eggs",
                            "dairy",
                            "nuts"
                        ],
                        "description": "Tiramisú con bizcochos en café y amaretto, crema de mascarpone y cacao.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_6780.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Tiramisu with sponge cakes in coffee and amaretto, mascarpone cream and cocoa.",
                        "dishNameEn": "Tiramisu"
                    },
                    {
                        "dishName": "Tarta de queso",
                        "price": 7.5,
                        "allergens": [
                            "eggs",
                            "dairy"
                        ],
                        "description": "Suave tarta de queso espolvoreada con semillas de amapola, acompañada de una delicada salsa de hibiscus.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_0707.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Soft cheesecake sprinkled with poppy seeds, accompanied by a delicate hibiscus sauce.",
                        "dishNameEn": "Cheesecake"
                    },
                    {
                        "dishName": "Tartine de chocolate con sorbete de fresa",
                        "price": 6.0,
                        "allergens": [
                            "eggs"
                        ],
                        "description": "Elegante tartine de chocolate oscuro servido con un refrescante sorbete de fresa casero.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_0716.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [
                            "starDish"
                        ],
                        "diets": [],
                        "descriptionEn": "Elegant dark chocolate tartine served with a refreshing homemade strawberry sorbet.",
                        "dishNameEn": "Tartine de chocolate con sorbete de fresa"
                    },
                    {
                        "dishName": "Macaron de maracujá",
                        "price": 6.0,
                        "allergens": [
                            "nuts",
                            "eggs"
                        ],
                        "description": "Macaron crujiente con un relleno cremoso de maracujá, perfecto para un toque tropical.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_0714.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [
                            "ChefRecommended"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Crunchy macaron with a creamy maracuja filling, perfect for a tropical touch.",
                        "dishNameEn": "Macaron of maracuja"
                    },
                    {
                        "dishName": "Petit four",
                        "price": 6.0,
                        "allergens": [
                            "eggs"
                        ],
                        "description": "Pequeños bocados de aireado merengue con un toque cítrico de lima y limón, una dulce acidez que encanta.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_0708.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Small bites of airy meringue with a hint of lime and lemon citrus, a sweet acidity that delights.",
                        "dishNameEn": "Small oven"
                    },
                    {
                        "dishName": "Bowl de acai y yogur",
                        "price": 8.0,
                        "allergens": [
                            "dairy",
                            "nuts"
                        ],
                        "description": "Bowl nutritivo de acai mezclado con yogur natural, coronado con una selección de frutas frescas y granola crujiente.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_0706.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Nutritious acai bowl blended with natural yoghurt, topped with a selection of fresh fruit and crunchy granola.",
                        "dishNameEn": "Acai and yoghurt bowl"
                    },
                    {
                        "dishName": "Pasteis de nata",
                        "price": 6.0,
                        "allergens": [
                            "eggs",
                            "dairy",
                            "nuts"
                        ],
                        "description": "Tradicionales tartas portuguesas de crema, con una base crujiente y un relleno suave y dulce, espolvoreadas con canela.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/postres/IMG_0711.webp",
                        "video": "",
                        "category": "Postre",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Traditional Portuguese custard tarts, with a crunchy base and a soft, sweet filling, sprinkled with cinnamon.",
                        "dishNameEn": "Custard tarts"
                    },
                    {
                        "dishName": "Gunkan de yema de huevo de codorniz",
                        "price": 7.0,
                        "allergens": [
                            "eggs",
                            "fish"
                        ],
                        "description": "Gunkan de arroz envuelto en nori, coronado con una yema de huevo de codorniz suavemente cocida.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gunkan/IMG_0845.webp",
                        "video": "",
                        "category": "Gunkan",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Rice gunkan wrapped in nori, topped with a soft-boiled quail egg yolk.",
                        "dishNameEn": "Quail egg yolk gunkan"
                    },
                    {
                        "dishName": "Gunkan de molleja y crema de palta",
                        "price": 8.0,
                        "allergens": [
                            "nuts"
                        ],
                        "description": "Delicado Gunkan de arroz con molleja tierna y una crema suave de palta, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gunkan/IMG_0852.webp",
                        "video": "",
                        "category": "Gunkan",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Delicate rice gunkan with tender sweetbread and a soft avocado cream, wrapped in nori.",
                        "dishNameEn": "Sweetbread and avocado cream gunkan"
                    },
                    {
                        "dishName": "Gunkan de atún rojo picante",
                        "price": 8.0,
                        "allergens": [
                            "fish",
                            "soy"
                        ],
                        "description": "Gunkan picante con atún rojo fresco picado y un toque de chile, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gunkan/IMG_0815.webp",
                        "video": "",
                        "category": "Gunkan",
                        "subcategory": "",
                        "features": [
                            "spicy"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Spicy gunkan with chopped fresh red tuna and a hint of chilli, wrapped in nori.",
                        "dishNameEn": "Spicy bluefin tuna gunkan"
                    },
                    {
                        "dishName": "Gunkan de wagyu",
                        "price": 10.0,
                        "allergens": [
                            "soy"
                        ],
                        "description": "Gunkan exclusivo con carne de wagyu ligeramente asada sobre arroz, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gunkan/IMG_0826.webp",
                        "video": "",
                        "category": "Gunkan",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Unique gunkan with lightly grilled wagyu beef over rice, wrapped in nori.",
                        "dishNameEn": "Gunkan de wagyu"
                    },
                    {
                        "dishName": "Gunkan de anguila",
                        "price": 7.0,
                        "allergens": [
                            "fish"
                        ],
                        "description": "Gunkan de arroz con anguila grillada y una salsa unagi dulce, envuelto en una banda de nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gunkan/IMG_0759.webp",
                        "video": "",
                        "category": "Gunkan",
                        "subcategory": "",
                        "features": [
                            "starDish"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Rice gunkan with grilled eel and sweet unagi sauce, wrapped in a nori band.",
                        "dishNameEn": "Gunkan de anguila"
                    },
                    {
                        "dishName": "Gunkan de erizo de mar",
                        "price": 9.0,
                        "allergens": [
                            "fish"
                        ],
                        "description": "Gunkan de arroz cubierto con erizo de mar fresco y cremoso, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gunkan/IMG_0764.webp",
                        "video": "",
                        "category": "Gunkan",
                        "subcategory": "",
                        "features": [
                            "ChefRecommended"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Rice gunkan topped with fresh and creamy sea urchin, wrapped in nori.",
                        "dishNameEn": "Sea urchin gunkan"
                    },
                    {
                        "dishName": "Maki de salmón con espárragos",
                        "price": 6.5,
                        "allergens": [
                            "fish",
                            "soy"
                        ],
                        "description": "Maki enrollado con salmón fresco y tiernos espárragos, servido con una ligera salsa teriyaki.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/maki/IMG_0847.webp",
                        "video": "",
                        "category": "Maki",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Rolled maki with fresh salmon and tender asparagus, served with a light teriyaki sauce.",
                        "dishNameEn": "Salmon maki with asparagus"
                    },
                    {
                        "dishName": "Maki de tempura de camarón",
                        "price": 6.5,
                        "allergens": [
                            "fish",
                            "soy"
                        ],
                        "description": "Maki crujiente de camarón en tempura con aguacate y un toque de mayonesa picante, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/maki/IMG_0832.webp",
                        "video": "",
                        "category": "Maki",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Crispy tempura shrimp maki with avocado and a touch of spicy mayonnaise, wrapped in nori.",
                        "dishNameEn": "Prawn tempura maki"
                    },
                    {
                        "dishName": "Maki de tobiko y pepino",
                        "price": 6.5,
                        "allergens": [
                            "fish",
                            "dairy"
                        ],
                        "description": "Refrescante maki de pepino con huevas de pez volador (tobiko) y queso crema, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/maki/IMG_0817.webp",
                        "video": "",
                        "category": "Maki",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Refreshing cucumber maki with flying fish roe (tobiko) and cream cheese, wrapped in nori.",
                        "dishNameEn": "Tobiko and cucumber maki"
                    },
                    {
                        "dishName": "Maki de pollo teriyaki",
                        "price": 5.5,
                        "allergens": [],
                        "description": "Maki con pollo asado en salsa teriyaki, acompañado de cebolla verde y envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/maki/IMG_0854.webp",
                        "video": "",
                        "category": "Maki",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Maki with grilled chicken in teriyaki sauce, served with green onion and wrapped in nori.",
                        "dishNameEn": "Maki de pollo teriyaki"
                    },
                    {
                        "dishName": "Maki de atún picante",
                        "price": 6.5,
                        "allergens": [
                            "fish"
                        ],
                        "description": "Maki con atún picante y aguacate, sazonado con una mezcla de sriracha y mayonesa japonesa, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/maki/IMG_0811.webp",
                        "video": "",
                        "category": "Maki",
                        "subcategory": "",
                        "features": [
                            "spicy"
                        ],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Maki with spicy tuna and avocado, seasoned with a mixture of sriracha and Japanese mayonnaise, wrapped in nori.",
                        "dishNameEn": "Spicy tuna maki"
                    },
                    {
                        "dishName": "Maki de unagi y mango",
                        "price": 6.5,
                        "allergens": [
                            "fish"
                        ],
                        "description": "Exótico maki de anguila (unagi) a la parrilla con rodajas de mango fresco y un toque de salsa unagi, envuelto en nori.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/maki/IMG_0824.webp",
                        "video": "",
                        "category": "Maki",
                        "subcategory": "",
                        "features": [],
                        "diets": [
                            "glutenFree"
                        ],
                        "descriptionEn": "Exotic grilled eel (unagi) maki with fresh mango slices and a touch of unagi sauce, wrapped in nori.",
                        "dishNameEn": "Unagi and mango maki"
                    },
                    {
                        "dishName": "Gyoza de cerdo",
                        "price": 6.5,
                        "allergens": [
                            "soy"
                        ],
                        "description": "Gyozas rellenas de cerdo y vegetales, a la plancha.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gyoza/IMG_0830.webp",
                        "video": "",
                        "category": "Gyoza",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Gyozas stuffed with pork and vegetables, grilled.",
                        "dishNameEn": "Pork gyoza"
                    },
                    {
                        "dishName": "Gyoza de camarón",
                        "price": 6.5,
                        "allergens": [
                            "fish",
                            "soy"
                        ],
                        "description": "Gyozas rellenas de camarón picado y cebollín.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gyoza/IMG_0851.webp",
                        "video": "",
                        "category": "Gyoza",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Gyozas stuffed with minced shrimp and spring onion.",
                        "dishNameEn": "Shrimp gyoza"
                    },
                    {
                        "dishName": "Gyoza vegetariana",
                        "price": 6.5,
                        "allergens": [
                            "soy"
                        ],
                        "description": "Gyozas de tofu, shiitake y espinacas.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gyoza/IMG_0806.webp",
                        "video": "",
                        "category": "Gyoza",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Tofu, shiitake and spinach gyozas.",
                        "dishNameEn": "Vegetarian gyoza"
                    },
                    {
                        "dishName": "Gyoza de pollo al curry",
                        "price": 6.5,
                        "allergens": [
                            "soy",
                            "nuts"
                        ],
                        "description": "Gyozas de pollo sazonado con curry suave.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gyoza/IMG_0813.webp",
                        "video": "",
                        "category": "Gyoza",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Chicken gyozas seasoned with mild curry.",
                        "dishNameEn": "Chicken curry gyoza"
                    },
                    {
                        "dishName": "Gyoza de ternera y cebolla",
                        "price": 6.5,
                        "allergens": [
                            "soy"
                        ],
                        "description": "Gyozas rellenas de ternera picada y cebolla caramelizada.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gyoza/IMG_0822.webp",
                        "video": "",
                        "category": "Gyoza",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Gyozas stuffed with minced beef and caramelised onion.",
                        "dishNameEn": "Beef and onion gyoza"
                    },
                    {
                        "dishName": "Gyoza de pato con ciruelas",
                        "price": 6.5,
                        "allergens": [
                            "soy"
                        ],
                        "description": "Gyozas de pato con relleno de ciruelas dulces.",
                        "photo": "https://assets.eyebite.es/5b716c45-c328-47c7-8676-d0e87973c5c5/1/gyoza/IMG_0804.webp",
                        "video": "",
                        "category": "Gyoza",
                        "subcategory": "",
                        "features": [],
                        "diets": [],
                        "descriptionEn": "Duck gyozas with sweet plum filling.",
                        "dishNameEn": "Duck gyoza with plums"
                    }
                ]
            }
            ]
        }]

    let restaurantDetails = restaurantsCatalog[0]
    if (restaurantId.length > 0) {
        restaurantDetails = restaurantsCatalog.find((restaurant) => restaurant.code === decodeURI(restaurantId))
    }


    const [sortedCatalogs, setSortedCatalogs] = React.useState(undefined);
    const [introTimeoutFinished, setIntroTimeoutFinished] = React.useState(false);
    const dispatch = useDispatch()


    useEffect(() => {

        setTimeout(() => {
            setIntroTimeoutFinished(true)
        }, 2000);
        //sort catalog item by category considering the order in catalog.categoryOrder
        dispatch(setRestaurantCode(restaurantDetails.code))
        setBannersLocal({
            bannerLocalHeader: restaurantDetails.bannerLocalHeader,
            bannerLocalFooter: restaurantDetails.bannerLocalFooter
        })

        const catalogs = []
        restaurantDetails.catalogs.forEach((catalog) => {

            const sortedC = []
            catalog.categoryOrder.forEach((category) => {
                //add restaurantDetails.catalog.filter((item) => item.category === category) to sortedC
                sortedC.push(...catalog.catalog.filter((item) => item.category === category))
            })

            catalogs.push({
                ...catalog,
                catalog: sortedC
            })
        })

        dispatch(setDishes(catalogs.flatMap((catalog) => catalog.catalog)))


        setSortedCatalogs(catalogs)


    }, []);

    useEffect(() => {
        if (sortedCatalogs !== undefined && introTimeoutFinished) {
            if (pathParams.length > 2) {
                const filterCat = sortedCatalogs.filter((catalog) => catalog.name === decodeURI(pathParams[2].replaceAll("-", " ")))[0]
                console.log(filterCat)
                //Get dishName index in filterCat.catalog

                const dishName = decodeURI(pathParams[3].replaceAll("-", " "))
                let match = 0
                let dishIndex = 0
                let prevCat = ""
                filterCat.catalog.forEach((dish) => {
                    if(prevCat !== dish.category){
                        dishIndex = 0
                        prevCat = dish.category
                    }
                    if(dish.dishName === dishName){
                        match = dishIndex
                    }
                    dishIndex++
                })
                setDisplayCatalogList(false)
                sortAndPrepareCatalog({...filterCat, startFromIndex: match, startFromFullIndex: filterCat.catalog.findIndex((dish) => dish.dishName === dishName)})
            }
            setCatalogs(sortedCatalogs)
        }
    }, [sortedCatalogs, introTimeoutFinished])


    return (
        <Container sx={{padding: 0, margin: 0}} className={"RestaurantIntroContainer"}>
            <Grid2 container columns={1} display={"flex"} alignItems={"stretch"} className={"MaxInnerHeight"}>
                <Grid2 item xs={1} key={"logoRest"} sx={{background: "white", border: "3px solid black", height: "20%"}}
                       display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    {
                        restaurantDetails.logoRest.length > 0 ?
                            <img src={restaurantDetails.logoRest} alt="restaurant logo" className={"restaurantLogo"}/> :
                            <span className={"HeadingRegularBold"} style={{color: "black"}}>Grupo Logo</span>
                    }
                </Grid2>
                <Grid2 item xs={1} key={"BannerRest"}
                       sx={{background: "white", border: "3px solid black", height: "50%"}} display={"flex"}
                       justifyContent={"center"} alignItems={"center"}>
                    {
                        restaurantDetails.bannerRest.length > 0 ?
                            <img src={restaurantDetails.bannerRest} alt="restaurant banner"
                                 className={"restaurantBanner"}/> :
                            <span className={"HeadingRegularBold"} style={{color: "black"}}>Grupo Banner</span>
                    }
                </Grid2>
                <Grid2 item xs={1} key={"BrandLogo"}
                       sx={{background: "white", border: "3px solid black", height: "30%"}}
                       display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                    <img src={eyebiteWrittenLogo} style={{height: "100px"}}/>
                    <img src={eyebiteGraphicLogo} style={{height: "100px"}}/>
                </Grid2>
            </Grid2>

        </Container>
    )
}
