import { configureStore } from '@reduxjs/toolkit'
import catalogReducer from './catalogSlide'
import orderReducer from './orderSlide'
import interactionReducer from './interactionSlide'

export default configureStore({
    reducer: {
        catalog: catalogReducer,
        orderList: orderReducer,
        interaction: interactionReducer
    },
})
