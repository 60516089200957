import {Button, Container} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {addDish, removeDish} from "../services/orderSlide";
import {useTranslation} from "react-i18next";


const displayPriceWithDecimal = (price) => {
    return price.toFixed(2);

}

export default function OrderList({setOrderList,clickMenuIcon}) {

    const orderList = useSelector(state => state.orderList.dishes);
    const allDishes = useSelector(state => state.catalog.dishes);
    const {t} = useTranslation()
    const dispatch = useDispatch()

    console.log(orderList)

    let total = 0;
    const listToDisplay = []
    //order orderList by catalogName

    let lastCatalogName = ""


    orderList.toSorted((a, b) => {
        if (a.catalogName > b.catalogName) {
            return 1;
        }
        if (a.catalogName < b.catalogName) {
            return -1;
        }
        return 0;
    }).forEach((item, index) => {
        const dishDetails = allDishes.filter((itemA) => item.dishName === itemA.dishName && item.catalogName === item.catalogName)[0]
        if (item.catalogName !== lastCatalogName) {

            if (index !== 0) {
                listToDisplay.push(<Grid2 item xs={12}>
                    <Grid2 container columns={12} display={"flex"} justifyContent={"space-between"}
                           sx={{padding: "10px"}} gap={1}>
                        <Grid2 item xs={6} display={"flex"} justifyContent={"flex-start"}>
                            <span className={"TextMediumBold"}>{t("component.orderList.total")}</span>
                        </Grid2>
                        <Grid2 item xs={5.5} display={"flex"} justifyContent={"flex-end"}>
                            <span className={"TextMediumBold"}>{displayPriceWithDecimal(total)} €</span>
                        </Grid2>
                    </Grid2>
                </Grid2>)
            }
            listToDisplay.push(<Grid2 item xs={12} key={"catalog" + index} display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{background: "#7d9a96", height:"40px", borderRadius:"24px",                            width: "90%",
            }}>
                <span className={"HeadingSmallBold"}>{item.catalogName} </span>
            </Grid2>)
            lastCatalogName = item.catalogName
            total = 0
        }
        listToDisplay.push(<Grid2 item xs={12} key={"dish" + index} display={"flex"} justifyContent={"center"}
                                  sx={{background: "black"}}>
            <Grid2 container columns={12} sx={{width: "100%"}} display={"flex"} gap={0.5}
                   justifyContent={"space-between"}>
                <Grid2 item xs={4}>
                    <img src={dishDetails.photo} alt={dishDetails.dishName}
                         style={{width: "100%",aspectRatio: "3/4"}}/>
                </Grid2>
                <Grid2 item xs={7.5}>
                    <Grid2 container columns={12} gap={1} display={"flex"} alignItems={"space-between"} sx={{height:"100%"}}>
                        <Grid2 item xs={12}>
                            <span className={"HeadingXSmallBold"}>{dishDetails.dishName}</span><br/><br/>
                            <span className={"HeadingXSmallBold"}>{displayPriceWithDecimal(dishDetails.price)} €</span>
                        </Grid2>
                        <Grid2 item xs={12} display={"flex"} justifyContent={"end"} alignItems={"center"} gap={1} sx={{marginRight:"5px"}}>
                            <img id={"addItemToOrderListicon" + dishDetails.dishName} style={{zIndex: 1000}}
                                 onClick={(event) => {
                                     event.stopPropagation()
                                     dispatch(removeDish(item))
                                 }} width={23} height={23}
                                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABxUlEQVR4nO2WTy8DURTFu8BG09ZWt6jEQv37Gv58FpR9q9umYqn4DlaMSnwJxcq/LQmlVvqTmxzJCzKdGTNB4iQvmWTOPffOfXfeeanUP/4SgAKwBhwBLeBJy549oASMJZF4FmgSHFbgTByJ+4EtoCvhO2AbmFc3BrXseQFoiINiNoG+qMmHgGOJPQNlIBMgLgNUgI7TjVyUL29K4Na2IMIHTAKX0jgBBsIEbynwGhgOm9zRGQZupFUPM3BdtX0qanJHb1rb8WrPQQKaqrj83eSO5oY0vV7EgjPtmRgLyAL30h71I66LtB1Xckd7R9qrfiRPpIUECliS9qEf6UKkkQQKeN/eMz9SW6T0F+9C4Yv4tF61/Qp4TLAAOyEND34FnIsUu6MB49Ju/fQQHviRSiI1EihgT9rLv/cgMsg+DZVUTACqPdv/DrvJyIw6gcwjmLm9yIyKQYM2VbFZaf4byfOOHdfCBPY5W2EXkrkIyYvAlTS80FczIOcU0ZGlZgMOXFVtNxyGvpJ96ERd+4cmeRdY1MGS1hrXf77rTLvF1CJfSl0AE8B+iJPYi2OAP8H+YfNztbUl87J1ar8YsJKEk/4jlSTeAMi5tbiWABeGAAAAAElFTkSuQmCC"
                                 alt={"eliminar"}/>

                            <span className={"HeadingXSmallBold"}>{item.quantity}</span>
                            <img id={"addItemToOrderListicon" + dishDetails.dishName} style={{zIndex: 1000}}
                                 onClick={(event) => {
                                     event.stopPropagation()
                                     dispatch(addDish(item))
                                 }} width={28} height={28}
                                 src={" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAeCAYAAADQBxWhAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHeSURBVHgB7ZZNKGxhGMef0V3d273XrVtWBhsfJU0oCx9ZW1splCykROyUz2wsWcnKQlkiNnZE2WClKDIon+VjgRWO39s8U2OcjznOZKH516+3Oc/XeZ/znueMSEbfTSHxKcuywiwR+AOXcBwKhQ4l3aJQNgzCvmWvA+iDPEmHSNQCd/AKy9AMlVAItdAIc1o8Ch0SRCQY0mQrUODhmw9L6j8knyzYqwmGfcaNaFyPn7j4XT/DpItPxMU2DY9QKj6KzujzCTvY63U3EQe7OXg3sCgpFszVhN0uPvGi9S4+/erzP9mWZeNfo+uSBNOsrg2SQtEKuOCFP5IAIv7U5NF87/TDxt+0I5p4gRa1sQzY+M5ju0/4PU6xqYTf5xKbXJ5FLfibdG0bEg+FmTz5sCKxURjXhk0ub5n30tw9ZLn4eB4k9XuA0eTrdonXJLbTWgkgilWz/IL1VIrugHlOTRJM7XAmH1tuL22xUZWDPQy7ZnI52As0fkxSlU6UKBxBjvgQ/v801pDtJ9YEl+kn7dBpRzYxZmbvwTUUy2dkBjacKF3w08Ev/pG/hSsod8vr+XdFdzkMrXAnsXd2C57gNxRBHZhWLkAnA+JC0iFt3QSsavte4Bw2zYGBEskoo6/UG9JsyGYBxS6KAAAAAElFTkSuQmCC"}
                                 alt={"agregar"}/>

                        </Grid2>
                    </Grid2>

                </Grid2>
            </Grid2>

        </Grid2>)

        total += dishDetails.price * item.quantity

    });

    listToDisplay.push(<Grid2 item xs={12}>
        <Grid2 container columns={12} display={"flex"} justifyContent={"space-between"}
               sx={{padding: "10px"}} gap={1}>
            <Grid2 item xs={6} display={"flex"} justifyContent={"flex-start"}>
                <span className={"TextMediumBold"}>{t("component.orderList.total")}</span>
            </Grid2>
            <Grid2 item xs={5.5} display={"flex"} justifyContent={"flex-end"}>
                <span className={"TextMediumBold"}>{displayPriceWithDecimal(total)} €</span>
            </Grid2>
        </Grid2>
    </Grid2>)

    return (
        <Container sx={{background: "black", height: "100%", padding: "0 0 0 0"}} display={"flex"}
                   justifyContent={"center"}>
            <Grid2 container columns={12} display={"flex"} justifyContent={"center"}
                   sx={{ padding: "20px", background: "black"}} gap={2}>
                <Grid2 item xs={5}>
                    <span className={"HeadingRegularBold"}>{t("component.orderList.order")}</span>
                </Grid2>
                <Grid2 item xs={5} key={"close"} display={"flex"}
                       justifyContent={"right"}>
                    <img onClick={() => {
                        setOrderList(false)
                        document.getElementById("MenuHeader").style.display = "block"
                        document.getElementById("CategoryMenu").style.display = "flex"
                        clickMenuIcon()
                    }} height={30}
                         src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABQElEQVR4nO2ZQWrDMBBFfZQucpIESvZRsrCbs3fRM4RAEnglpKYO2CCPNaOR0d8ZjP5/GslI46apqqqqWpWADRAy+odnhqWDfADfwAM4J0sX738C7sCPGGYA0csUhn+IXjIY4PgXfqjnc6eS/N27m/A+ppoV9cqg5WkJg7aXBYzZhKFoZL6EUTDMsQ+TG2eDSBkgO0SKIG4glgRyByEJ5hZiTkD3EDFBi4GIOOxlOXwu0sTsl1GJGTDlQKwGhDUsLaAtfrOzhs8vr/v9WNCvwTu+YYiAcA/DDAi3MAgg3MHwamOKIDzdDsNSCA/39TBh3JXUQQmpIXL0tIIWhGWXMQA3TQiLvu9hAqJNll4bhvHldBe39tPcNFvJYJ/A1aoSEZW5ADvpYD2MKcQIjBxiMNg+88/QFtjm8q+qqqpqVPQL08n6GKXmFcYAAAAASUVORK5CYII=">
                    </img>
                </Grid2>
                {listToDisplay}
                <Grid2 item xs={12} key={"continueButton"} display={"flex"} justifyContent={"center"}>
                    <Button
                        onClick={() => {
                            console.log("continue")
                        }}
                        sx={{
                            background: "#de764c",
                            color: "blue",
                            width: "90%",
                            height: "50px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            textTransform: "none",
                            borderRadius: "24px",
                            "&:hover": {
                                background: "rgba(66,110,134,0.67)",
                                color: "black",
                            }
                        }}>
                        <span className={"TextMediumBold"}>{t("component.orderList.continue")}</span>
                    </Button>
                </Grid2>

            </Grid2>
        </Container>)
}
