import {createSlice} from '@reduxjs/toolkit'

export const interactionSlide = createSlice({
    name: 'interaction',
    initialState: {
       headerToDisplay:""
    },
    reducers: {
        setHeaderToDisplay : (state, action)=> {
            state.headerToDisplay = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setHeaderToDisplay} = interactionSlide.actions

export default interactionSlide.reducer
