import logo from './logo.svg';
import './App.css';
import './i18n';
import RestaurantGontainer from "./views/RestaurantGontainer";

function App() {
  return (
    <RestaurantGontainer/>
  );
}

export default App;
