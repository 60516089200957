import Grid2 from "@mui/material/Unstable_Grid2";
import {Container} from "@mui/material";
import React from "react";

export default function RestaurantCatalogList({catalogs,setCatalog, bannersLocal}){

    const gridList = []


    if(bannersLocal.bannerLocalHeader !== undefined){
        gridList.push(<Grid2 item xs={12} key={"bannerLocalHeader"} sx={{background :"white",border: "3px solid black"}} display={"flex"} justifyContent={"center"} alignItems={"center"} >
            {
                bannersLocal.bannerLocalHeader.length > 0 ? <img src={bannersLocal.bannerLocalHeader} alt={"bannerLocalHeader"} style={{width: "100%", height: "100%"}}/> :
                    <span className={"HeadingRegularBold"} style={{color: "black"}}>{bannersLocal.bannerLocalHeader} header</span>
            }
        </Grid2>)
    }
    //All the items in the grid will have the same height distributed based on the catalogs list
    catalogs.forEach((catalog, index) => {
        gridList.push(<Grid2 item xs={12} key={"catalog" + index} onClick={()=> {
            setCatalog(catalog)
        }} sx={{background :"white",border: "3px solid black"}} display={"flex"} justifyContent={"center"} alignItems={"center"} >
            {
                catalog.mainImage.length > 0 ?
                    <img src={catalog.mainImage} alt={catalog.name} style={{width: "100%", height: "100%"}}/> :
                    <span className={"HeadingRegularBold"} style={{color: "black"}}>{catalog.name} logo</span>
            }
        </Grid2>)
    })

    if (bannersLocal.bannerLocalFooter !== undefined) {
        gridList.push(<Grid2 item xs={12} key={"bannerLocalFooter"} sx={{background :"white",border: "3px solid black"}} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            {
                bannersLocal.bannerLocalFooter.length > 0 ? <img src={bannersLocal.bannerLocalFooter} alt={"bannerLocalFooter"} style={{width: "100%", height: "100%"}}/> :
                    <span className={"HeadingRegularBold"} style={{color: "black"}}>{bannersLocal.bannerLocalFooter} footer</span>
            }
        </Grid2>)
    }

    return (<Container className={"RestaurantCatalogListContainer"} id={"RestaurantCatalogListContainer"}>
        <Grid2 container columns={12} display={"flex"} className={"MaxInnerHeight"} alignItems={"stretch"} >
            {gridList}

        </Grid2>
    </Container>
    )
}
