const speed = 500,
    curveDelay = 300,
    position = "fixed"; // or absolute

function addItem(e) {

    let cart = document.getElementById("orderListIcon")
    let btnY =
            position === "fixed"
                ? e.getBoundingClientRect().top
                : e.offsetTop,
        btnX =
            position === "fixed"
                ? e.getBoundingClientRect().left
                : e.offsetLeft,
        flyingBtn = e.cloneNode();


    flyingBtn.classList.add("flyingBtn");
    flyingBtn.style.position = position;
    flyingBtn.style.top = `${btnY}px`;
    flyingBtn.style.left = `${btnX}px`;
    flyingBtn.style.opacity = "1";
    flyingBtn.style.transition = `all ${speed / 1000}s ease, top ${(speed +
        curveDelay) /
    1000}s ease, left ${speed / 1000}s ease, transform ${speed /
    1000}s ease ${(speed - 10) / 1000}s`;

    document.body.appendChild(flyingBtn);

    flyingBtn.style.top = `${cart.offsetTop + cart.offsetHeight - 16}px`;
    flyingBtn.style.left = `${cart.offsetLeft + cart.offsetWidth - 16}px`;
    flyingBtn.style.height = "1rem";
    flyingBtn.style.width = "1rem";
    flyingBtn.style.transform = "scale(0)";

    setTimeout(() => {
        flyingBtn.remove();
        //storeItems();
    }, speed * 1.5);
}

export default addItem;
