import MenuHeader from "./MenuHeader";
import React, {useEffect} from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import DishCatalogGrid from "./DishCatalogGrid";
import DishCatalogSlider from "./DishCatalogSlider";
import {Dialog} from "@mui/material";
import OrderList from "./OrderList";
import FilterCatalogList from "./FilterCatalogList";
import {useSelector} from "react-redux";
import MenuCategory from "./MenuCategory";

export default function RestaurantCatalog({sortedCatalog, goToIntro}) {

    console.log("sortedCatalog", sortedCatalog)

    const [seeOrderList, setSeeOrderList] = React.useState(false)
    const [seeFilterList, setSeeFilterList] = React.useState(false)
    const [displayCatalogSlider, setDisplayCatalogSlider] = React.useState(sortedCatalog.startFromIndex > 0)
    const [dishStartIndex, setDishStartIndex] = React.useState(sortedCatalog.startFromIndex)
    const filters = useSelector((state) => state.catalog.filters)
    const [filterCatalog, setFilterCatalog] = React.useState(undefined)
    const currentCategory = useSelector((state) => state.catalog.currentCategory)

    const clickMenuIcon = () => {
        document.getElementById("DishCatalogGrid").style.display = "block";
        document.getElementById("DishCatalogGrid").scrollIntoView({behavior: 'smooth'});

        setDisplayCatalogSlider(false);
        document.getElementById("DishCatalogSlider").style.display = "none";
    }

    const displaySlider = (index) => {
        console.log("displaySlider", index)
        setDisplayCatalogSlider(true)
        window.swiperInstance.slideTo(index)
        /*
        if (sortedCatalog[index] !== undefined && sortedCatalog[index].video === undefined && document.getElementById("videoSlide") !== null) {
            document.getElementById("videoSlide").style.display = "none";
        } else if (document.getElementById("videoSlide") !== null) {
            document.getElementById("videoSlide").style.display = "block";
        }

         */
        document.getElementById("DishCatalogGrid").style.display = "none";

        const dishCatalogSlider = document.getElementById("DishCatalogSlider");
        dishCatalogSlider.style.display = "block";

        const y = dishCatalogSlider.getBoundingClientRect().top + window.pageYOffset + 10;

        window.scrollTo({top: y, behavior: 'smooth'});

        setTimeout(() => {
            document.getElementById("headerMenuInner").style.top = "-100px"
            document.getElementById("buttonDisplayMenu").style.top = "10px"
        }, 2500)

    }


    useEffect(() => {
        console.log("useEffectFilterCatalog")
        if (filterCatalog !== undefined) {
            console.log(displayCatalogSlider, "displayCatalogSlider")
            setTimeout(() => {
                const catalog = !displayCatalogSlider ? document.getElementById("DishCatalogGrid") : document.getElementById("DishCatalogSlider");
                catalog.style.display = "block";
                document.getElementById("MenuHeader").style.display = "block";
                document.getElementById("CategoryMenu").style.display = "flex";
                catalog.scrollIntoView({behavior: 'smooth'});
            }, 200);
            setTimeout(() => {
                const RestaurantCatalogListContainer = document.getElementById("RestaurantCatalogListContainer");
                if (RestaurantCatalogListContainer !== null) {
                    RestaurantCatalogListContainer.style.display = "none";
                    window.scrollTo({top: 1});
                }

            }, 800);

            const headerMenuEl = document.getElementById("headerMenuInner");
            const buttonDisplayMenuEl = document.getElementById("buttonDisplayMenu");
            headerMenuEl.style.top = "0px";
            buttonDisplayMenuEl.style.top = "-100px";

            if (displayCatalogSlider) {
                setTimeout(() => {
                    headerMenuEl.style.top = "-100px"
                    buttonDisplayMenuEl.style.top = "10px"
                }, 2500)
            }


            // document.getElementById("MenuHeader").style.display = "block";

        }
    }, [filterCatalog]);


    useEffect(() => {
        console.log("useEffectCatalog")
        if (sortedCatalog !== undefined) {
            let catalogTODisplay = sortedCatalog.catalog.filter(dish => dish.category === currentCategory).toSorted((a, b) => a.subcategory?.localeCompare(b.subcategory))
            if (filters !== undefined && filters.allergens.length > 0) {

                catalogTODisplay = catalogTODisplay.filter(dish => {
                    let found = false
                    filters.allergens.forEach(filter => {
                        if (!dish.allergens.includes(filter)) {
                            found = true
                        }
                    })
                    return found
                })
            }

            if (filters !== undefined && filters.diets.length > 0) {
                catalogTODisplay = catalogTODisplay.filter(dish => {
                    let found = false
                    filters.diets.forEach(filter => {
                        if (dish.diets.includes(filter)) {
                            found = true
                        }
                    })
                    return found
                })
            }

            setFilterCatalog(catalogTODisplay)
        }
    }, [sortedCatalog, currentCategory, filters]);

    return (<div style={{paddingBottom: "70px"}}>
        <div>
            <Dialog sx={{zIndex: "3000"}}
                    fullScreen={true}
                    open={seeOrderList}
                    onClose={() => {
                        setSeeOrderList(false)
                    }}
            ><OrderList setOrderList={setSeeOrderList} clickMenuIcon={clickMenuIcon}/></Dialog>
        </div>
        <div>
            <Dialog sx={{zIndex: "3000"}}
                    fullScreen={true}
                    open={seeFilterList}
                    onClose={() => {
                        setSeeFilterList(false)
                    }}
            ><FilterCatalogList setSeeFilterList={setSeeFilterList} clickMenuIcon={clickMenuIcon}/></Dialog>
        </div>
        <div id={"MenuHeader"} key={"MenuHeader"} className={"menuHeader"} style={{display: "none", width: "100%"}}>
            <MenuHeader clickMenuIcon={clickMenuIcon} setSeeOrderList={setSeeOrderList} seeOrderList={seeOrderList}
                        setSeeFilterlist={setSeeFilterList}
                        seeFilterList={seeFilterList} goToIntro={goToIntro}/>
        </div>
        <Grid2 container columns={12} sx={{width: "100%"}} className={"CatalogAndMenuCategory"}>
            {filterCatalog !== undefined ?
                <Grid2 item xs={12} id={"DishCatalogGrid"} key={"DishCatalogGrid"} style={{display: "none"}}>
                    <DishCatalogGrid catalog={filterCatalog} displaySlider={displaySlider}/>
                </Grid2> : null}
            {filterCatalog !== undefined ?
                <Grid2 item xs={12} id={"DishCatalogSlider"} key={"DishCatalogSlider"} style={{display: "none"}}>

                    <DishCatalogSlider dishStartIndex={dishStartIndex} catalog={filterCatalog}
                                       restaurantId={sortedCatalog.name} clickMenuIcon={clickMenuIcon}/>
                </Grid2> : null
            }

            <Grid2 item xs={12}>
                <MenuCategory/>
            </Grid2>
        </Grid2>
    </div>)

}
