import Grid2 from "@mui/material/Unstable_Grid2";
import React, {useEffect, useRef} from "react";
import Dish from "./Dish";



export default function DishCatalogGrid({catalog, displaySlider}) {



    const [items, setItems] = React.useState([])

    useEffect(() => {

        let its = []
        let prevCategory = undefined

        console.log(catalog)
        catalog.forEach((dishItem, index) => {
            if (dishItem.subcategory !== "" && prevCategory !== dishItem.subcategory) {
                its.push(<Grid2 item xs={2} sx={{ height:"50px"}} display={"flex"} justifyContent={"center"} alignItems={"center"}
                                  key={"dishCatalogTitle" + index}
                                  id={dishItem.subcategory}>
                    <span className={"HeadingSmallBold"}>{dishItem.subcategory}</span>
                </Grid2>)

                prevCategory = dishItem.subcategory
            }
            its.push(<Grid2 item xs={1} display={"flex"} justifyContent={"center"} key={"dishCatalog" + index}
                              onClick={() => {
                                  displaySlider(index)

                              }}><Dish
                dish={dishItem} key={index}/></Grid2>)
        })

        setItems(its)
    }, [catalog])



    return (<Grid2 id={"DishCatalogGrid"} container spacing={0} columns={2} gap={0} sx={{paddingTop:"55px"}}>
        {
            items
        }
    </Grid2>)

}
