import {Container} from "@mui/material";
import React from "react";
import VideoClip from "./VideoClip";


export default function DishFullView({dish, restaurantId}) {




    return (
        <Container className={"dishFullView"} id={dish.dishName} sx={{
            padding: "0"
        }}>
            {
                dish.video === undefined || dish.video.length === 0 ?
                    <img className={"catalogPhoto"} src={dish.photo} alt={dish.dishName}
                    />
                    :

                    <div className={"videoSlide"} id={"videoSlide"} style={{display: "block"}}>
                        <VideoClip url={dish.video} poster={dish.photo}/>
                    </div>
            }
            <div className="shadow"/>


        </Container>
    )
}
