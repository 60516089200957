import {createSlice} from '@reduxjs/toolkit'

export const orderSlide = createSlice({
    name: 'order',
    initialState: {
        dishes: []
    },
    reducers: {
        addDish: (state, action) => {

            //check if the dish is already in the order to set quantity
            let dish = state.dishes.find(dish => dish.dishName === action.payload.dishName && dish.catalogName === action.payload.catalogName)
            if (dish) {
                dish.quantity += 1
                return
            }
            dish = {...action.payload}
            dish.quantity = 1
            state.dishes.push(dish)
        },
        removeDish: (state, action) => {
            //Reduce quantity of the dish or remove it from the order
            let dish = state.dishes.find(dish => dish.dishName === action.payload.dishName && dish.catalogName === action.payload.catalogName)
            if (dish) {
                if (dish.quantity > 1) {
                    dish.quantity -= 1
                    return
                }
                state.dishes = state.dishes.filter(dish => dish.dishName !== action.payload.dishName)
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {addDish,removeDish} = orderSlide.actions

export default orderSlide.reducer
