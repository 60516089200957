import {createSlice} from '@reduxjs/toolkit'

export const catalogSlide = createSlice({
    name: 'catalog',
    initialState: {
        allCategories: [],
        currentCategory: "",
        restaurantName: "",
        restaurantCode: "",
        filters: {allergens: [], diets: []},
        dishes: [],
        currentCatalogName: "",
        startFromIndex: 0
    },
    reducers: {
        setCurrentCategory: (state, action) => {
            state.currentCategory = action.payload
        },
        setRestaurantName: (state, action) => {
            state.restaurantName = action.payload
        },
        setRestaurantCode: (state, action) => {
            state.restaurantCode = action.payload
        },
        setFilters: (state, action) => {
            state.filters = {...state.filters, ...action.payload}
        },
        setAllCategories: (state, action) => {
            state.allCategories = action.payload
        },
        setDishes: (state, action) => {
            state.dishes = action.payload

        },

        initCatalog: (state, action) => {
            state.allCategories = action.payload.allCategories
            state.currentCatalogName = action.payload.currentCatalogName
            state.currentCategory = action.payload.currentCategory
            state.startFromIndex = action.payload.startFromIndex

        }
    },
})

// Action creators are generated for each case reducer function
export const {
    initCatalog,
    setCurrentCategory,
    setRestaurantName,
    setFilters,
    setAllCategories,
    setDishes,
    setRestaurantCode
} = catalogSlide.actions

export default catalogSlide.reducer
