import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init


//get the language from the browser
const language = navigator.language.split(/[-_]/)[0];  // language without region code

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        lng: language,
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            es: {
                translation: {
                    component: {
                        menuHeader:
                            {
                                category: {
                                    cocteles: "Cócteles",
                                    postre: "Postres",
                                    arroces: "Arroces",
                                    nigiris: "Nigiris",
                                    maki: "Maki",
                                    gunkan: "Gunkan",
                                    gyoza: "Gyoza",
                                }
                            },
                        dishFullView: {
                            features: {
                                starDish: "Plato Estrella",
                                ChefRecommended: "Recomendado por el Chef",
                                spicy: "Picante",
                            },
                            allergens: {
                                nuts: "frutos secos",
                                dairy: "lácteos",
                                none: "ninguno",
                                soy: "soja",
                                gluten: "gluten",
                                fish: "pescado",
                                lupin: "altramuces",
                                molluscs: "moluscos",
                                mustard: "mostaza",
                                peanuts: "cacahuetes",
                                sesame: "sésamo",
                                sulphites: "sulfitos",
                                celery: "apio",
                                eggs: "huevos",
                                crustaceans: "crustáceos",
                                without: "sin"

                            },
                            diets: {
                                vegan: "vegano",
                                vegetarian: "vegetariano",
                                glutenFree: "sin gluten"
                            }
                        },
                        filterCatalogList: {
                            removeFilters: "Quitar filtros",
                            byAllergens: "Por alérgenos",
                            byDiets: "Por dietas",
                            filters: "Filtros",
                            language: "Idiomas",
                        },
                        orderList: {
                            total: "Total",
                            order: "Pedido",
                            continue: "Continuar",
                        },
                        languages:{
                            es: "Español",
                            en: "Inglés"
                        }
                    }
                }
            }, en: {
                translation: {
                    component: {
                        menuHeader:
                            {
                                category: {
                                    cocteles: "Cocktails",
                                    postre: "Desserts",
                                    arroces: "Rices",
                                    nigiris: "Nigiris",
                                    maki: "Maki",
                                    gunkan: "Gunkan",
                                    gyoza: "Gyoza",
                                }
                            },
                        dishFullView: {
                            features: {
                                starDish: "Star Dish",
                                ChefRecommended: "Chef Recommended",
                                spicy: "Spicy",
                            },
                            allergens: {
                                nuts: "nuts",
                                dairy: "dairy",
                                none: "none",
                                soy: "soy",
                                fish: "fish",
                                lupin: "lupin",
                                molluscs: "molluscs",
                                mustard: "mustard",
                                peanuts: "peanuts",
                                sesame: "sesame",
                                sulphites: "sulphites",
                                celery: "celery",
                                eggs: "eggs",
                                crustaceans: "crustaceans",
                                without: "without"


                            },
                            diets: {
                                vegan: "vegan",
                                vegetarian: "vegetarian",
                                glutenFree: "gluten"
                            }
                        },
                        filterCatalogList: {
                            removeFilters: "Remove filters",
                            byAllergens: "By allergens",
                            byDiets: "By diets",
                            filters: "Filters",
                            language: "Languages",
                        },
                        orderList: {
                            total: "Total",
                            order: "Order",
                            continue: "Continue",
                        },
                        languages:{
                            es: "Spanish",
                            en: "English"
                        }

                    }


                }
            }

        }
    });


export default i18n;
