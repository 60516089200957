import Grid2 from "@mui/material/Unstable_Grid2";
import React from "react";
import {Container, FormControl, MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setFilters} from "../services/catalogSlide";
import sinAltramuces from "../images/icons/SinAltramuces.png";
import sinApio from "../images/icons/SinApio.png";
import sinCacahuetes from "../images/icons/SinCacahuetes.png";
import sinCrustaceos from "../images/icons/SinCrustaceos.png";
import sinFrutosSecos from "../images/icons/SinFrutossecos.png";
import sinGluten from "../images/icons/SinGluten.png";
import sinHuevos from "../images/icons/SinHuevos.png";
import sinLacteos from "../images/icons/SinLacteos.png";
import sinMoluscos from "../images/icons/SinMoluscos.png";
import sinMostaza from "../images/icons/SinMostaza.png";
import sinPescado from "../images/icons/SinPescado.png";
import sinSesamo from "../images/icons/SinSesamo.png";
import sinSoja from "../images/icons/SinSoja.png";
import sinSulfitos from "../images/icons/SinSulfitos.png";
import vegano from "../images/icons/Vegano.png";

const allergensIcons = [

    {
        name: "dairy",
        icon: sinLacteos
    },
    {
        //frutos secos
        name: "nuts",
        icon: sinFrutosSecos
    },
    {
        //altramuces
        name: "lupin",
        icon: sinAltramuces
    },
    {
        //apio
        name: "celery",
        icon: sinApio
    },
    {
        //cacahuetes
        name: "peanuts",
        icon: sinCacahuetes
    },
    {
        //crustaceos
        name: "crustaceans",
        icon: sinCrustaceos

    },
    {
        //pescado
        name: "fish",
        icon: sinPescado
    },

    {
        //moluscos
        name: "molluscs",
        icon: sinMoluscos
    },
    {
        //huevos
        name: "eggs",
        icon: sinHuevos

    },
    {
        //mostaza
        name: "mustard",
        icon: sinMostaza
    }, {
        //sesamo
        name: "sesame",
        icon: sinSesamo
    },
    {
        //soja
        name: "soy",
        icon: sinSoja
    },
    {
        //sulfitos
        name: "sulphites",
        icon: sinSulfitos
    }

]

const dietIcons = [
    {name: "vegan", icon: vegano},
    {name: "vegetarian", icon: vegano},
    {name: "glutenFree", icon: sinGluten},
]

const getAllergenIcon = (allergen) => {
    const all = allergensIcons.filter((icon) => {
        return icon.name === allergen;
    })

    if (all.length > 0) {
        return (<img width={22} height={22} src={all[0].icon}/>);
    } else {
        return null
    }

}

export default function FilterCatalogList({setSeeFilterList, clickMenuIcon}) {


    const {t, i18n} = useTranslation()
    const currentFilters = useSelector((state) => state.catalog.filters)
    const [filtersApplied, setFiltersApplied] = React.useState(currentFilters)
    const dispatch = useDispatch()

    const applyFilters = (newFilters) => {
        setFiltersApplied(newFilters)

    }


    return (<Container sx={{background: "black", height: "100%"}} display={"flex"} justifyContent={"center"}>
        <Grid2 container columns={12} display={"flex"} justifyContent={"center"}
               sx={{padding: "20px"}} gap={2}>
            <Grid2 item xs={12} key={"closeButton"} display={"flex"}>
                <Grid2 container columns={2} rowGap={2} sx={{width: "100%"}} display={"flex"}
                       justifyContent={"space-between"}>
                    <Grid2 item xs={1}>
                        <span className={"HeadingRegularBold"}>{t("component.filterCatalogList.filters")}</span>
                    </Grid2>
                    <Grid2 item xs={1} key={"close"} display={"flex"}
                           justifyContent={"right"}>
                        <img onClick={() => {
                            dispatch(setFilters(filtersApplied))
                            setSeeFilterList(false)
                            document.getElementById("MenuHeader").style.display = "block"
                            document.getElementById("CategoryMenu").style.display = "flex"
                            clickMenuIcon()

                        }} height={30}
                             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABQElEQVR4nO2ZQWrDMBBFfZQucpIESvZRsrCbs3fRM4RAEnglpKYO2CCPNaOR0d8ZjP5/GslI46apqqqqWpWADRAy+odnhqWDfADfwAM4J0sX738C7sCPGGYA0csUhn+IXjIY4PgXfqjnc6eS/N27m/A+ppoV9cqg5WkJg7aXBYzZhKFoZL6EUTDMsQ+TG2eDSBkgO0SKIG4glgRyByEJ5hZiTkD3EDFBi4GIOOxlOXwu0sTsl1GJGTDlQKwGhDUsLaAtfrOzhs8vr/v9WNCvwTu+YYiAcA/DDAi3MAgg3MHwamOKIDzdDsNSCA/39TBh3JXUQQmpIXL0tIIWhGWXMQA3TQiLvu9hAqJNll4bhvHldBe39tPcNFvJYJ/A1aoSEZW5ADvpYD2MKcQIjBxiMNg+88/QFtjm8q+qqqpqVPQL08n6GKXmFcYAAAAASUVORK5CYII=">
                        </img>
                    </Grid2>
                    <Grid2 item xs={12}>
                        <span className={"TextMediumBold"}
                              style={{color: filtersApplied.diets.length > 0 ? "white" : "gray"}}>{t("component.filterCatalogList.byDiets")}</span>
                    </Grid2>
                    <Grid2 item xs={12}>
                        <Grid2 container columns={12}>
                            {
                                dietIcons.map((diet, index) => (
                                    <Grid2 item xs={5} display={"flex"} alignItems={"center"} gap={1}
                                           id={diet.name} onClick={() => {
                                        //add or remove diet.name
                                        const newFiltersDiets = [...filtersApplied.diets]

                                        const index = newFiltersDiets.indexOf(diet.name)
                                        if (index > -1) {
                                            newFiltersDiets.splice(index, 1)
                                        } else {
                                            newFiltersDiets.push(diet.name)
                                        }
                                        applyFilters({...filtersApplied, diets: newFiltersDiets})
                                    }} sx={{padding: "5px", borderRadius: "16px"}}>
                                        <img width={24} height={24} src={diet.icon}/> <span
                                        className={"TextSmallBold"}
                                        style={{color: filtersApplied.diets.indexOf(diet.name) > -1 ? "white" : "gray"}}>{t("component.dishFullView.diets." + diet.name)}</span>
                                    </Grid2>
                                ))
                            }

                        </Grid2>
                    </Grid2>
                    <Grid2 item xs={12}>
                        <span className={"TextMediumBold"}
                              style={{color: filtersApplied.allergens.length > 0 ? "white" : "gray"}}>{t("component.filterCatalogList.byAllergens")}</span>
                    </Grid2>
                    <Grid2 item xs={2}>
                        <Grid2 container columns={12} gap={1}>
                            {
                                allergensIcons.map((allergen, index) => (
                                    <Grid2 item xs={5} display={"flex"} alignItems={"center"} gap={1}
                                           id={allergen.name} onClick={() => {

                                        //add or remove allergen.name
                                        const newFiltersAllergens = [...filtersApplied.allergens]

                                        const index = newFiltersAllergens.indexOf(allergen.name)
                                        if (index > -1) {
                                            newFiltersAllergens.splice(index, 1)
                                        } else {
                                            newFiltersAllergens.push(allergen.name)
                                        }
                                        applyFilters({...filtersApplied, allergens: newFiltersAllergens})


                                    }} sx={{padding: "5px", borderRadius: "16px"}}>
                                        <img width={24} height={24} src={allergen.icon}/> <span
                                        className={"TextSmallBold"}
                                        style={{color: filtersApplied.allergens.indexOf(allergen.name) > -1 ? "white" : "gray"}}>{t("component.dishFullView.allergens.without")} {t("component.dishFullView.allergens." + allergen.name)}</span>
                                    </Grid2>
                                ))
                            }
                        </Grid2>
                    </Grid2>

                    {
                        filtersApplied.allergens.length > 0 || filtersApplied.diets.length > 0 ?
                            <Grid2 item display={"flex"} justifyContent={"left"} onClick={() => {
                                applyFilters({allergens: [], diets: []})

                            }}>
                                <img height={16} style={{marginRight: "5px"}}
                                     src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABQElEQVR4nO2ZQWrDMBBFfZQucpIESvZRsrCbs3fRM4RAEnglpKYO2CCPNaOR0d8ZjP5/GslI46apqqqqWpWADRAy+odnhqWDfADfwAM4J0sX738C7sCPGGYA0csUhn+IXjIY4PgXfqjnc6eS/N27m/A+ppoV9cqg5WkJg7aXBYzZhKFoZL6EUTDMsQ+TG2eDSBkgO0SKIG4glgRyByEJ5hZiTkD3EDFBi4GIOOxlOXwu0sTsl1GJGTDlQKwGhDUsLaAtfrOzhs8vr/v9WNCvwTu+YYiAcA/DDAi3MAgg3MHwamOKIDzdDsNSCA/39TBh3JXUQQmpIXL0tIIWhGWXMQA3TQiLvu9hAqJNll4bhvHldBe39tPcNFvJYJ/A1aoSEZW5ADvpYD2MKcQIjBxiMNg+88/QFtjm8q+qqqpqVPQL08n6GKXmFcYAAAAASUVORK5CYII=">
                                </img> <span className={"TextSmallBold"}
                                             style={{color: "white"}}>{t("component.filterCatalogList.removeFilters")}</span>
                            </Grid2> : null
                    }

                </Grid2>
            </Grid2>
            <Grid2 item xs={12} key={"languageSelector"} display={"flex"} justifyContent={"space-around"} sx={{position:"absolute", bottom:"25px", width:"80%"}}>
                <span onClick={()=>{
                    i18n.changeLanguage("es")

                }}
                    className={"TextSmallBold"}
                    style={{color: i18n.language === "es" ? "white" : "gray"}}>{t("component.languages.es")}</span>
                <span onClick={()=>{
                    i18n.changeLanguage("en")

                }}
                    className={"TextSmallBold"}
                    style={{color: i18n.language === "en" ? "white" : "gray"}}>{t("component.languages.en")}</span>
            </Grid2>
        </Grid2></Container>)
}
