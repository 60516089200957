import {useEffect, useRef} from "react";

export default function VideoClip({url,poster}) {

    const videoRef = useRef();

    useEffect(() => {
        if (url) {
            videoRef.current?.load();
            videoRef.current?.play();
        }
    }, [url]);

    return (
        <video ref={videoRef} style={{pointerEvents: "none",objectFit: "cover"}} playsInline muted loop id={"videoSlideDish"} className={"VideoClipVideo"} poster={poster}>
            <source src={url}/>
        </video>
    );
}
